// source: core/contract/smart_contract.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('@tronweb3/google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    function () {
        return this;
    }.call(null) ||
    Function('return this')();

var core_Tron_pb = require('../../core/Tron_pb.cjs');
goog.object.extend(proto, core_Tron_pb);
goog.exportSymbol('TronWebProto.ClearABIContract', null, global);
goog.exportSymbol('TronWebProto.ContractState', null, global);
goog.exportSymbol('TronWebProto.CreateSmartContract', null, global);
goog.exportSymbol('TronWebProto.SmartContract', null, global);
goog.exportSymbol('TronWebProto.SmartContract.ABI', null, global);
goog.exportSymbol('TronWebProto.SmartContract.ABI.Entry', null, global);
goog.exportSymbol('TronWebProto.SmartContract.ABI.Entry.EntryType', null, global);
goog.exportSymbol('TronWebProto.SmartContract.ABI.Entry.Param', null, global);
goog.exportSymbol('TronWebProto.SmartContract.ABI.Entry.StateMutabilityType', null, global);
goog.exportSymbol('TronWebProto.SmartContractDataWrapper', null, global);
goog.exportSymbol('TronWebProto.TriggerSmartContract', null, global);
goog.exportSymbol('TronWebProto.UpdateEnergyLimitContract', null, global);
goog.exportSymbol('TronWebProto.UpdateSettingContract', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.SmartContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.SmartContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.SmartContract.displayName = 'TronWebProto.SmartContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.SmartContract.ABI = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.SmartContract.ABI.repeatedFields_, null);
};
goog.inherits(TronWebProto.SmartContract.ABI, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.SmartContract.ABI.displayName = 'TronWebProto.SmartContract.ABI';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.SmartContract.ABI.Entry = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.SmartContract.ABI.Entry.repeatedFields_, null);
};
goog.inherits(TronWebProto.SmartContract.ABI.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.SmartContract.ABI.Entry.displayName = 'TronWebProto.SmartContract.ABI.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.SmartContract.ABI.Entry.Param = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.SmartContract.ABI.Entry.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.SmartContract.ABI.Entry.Param.displayName = 'TronWebProto.SmartContract.ABI.Entry.Param';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.ContractState = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.ContractState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.ContractState.displayName = 'TronWebProto.ContractState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.CreateSmartContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.CreateSmartContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.CreateSmartContract.displayName = 'TronWebProto.CreateSmartContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.TriggerSmartContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.TriggerSmartContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.TriggerSmartContract.displayName = 'TronWebProto.TriggerSmartContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.ClearABIContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.ClearABIContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.ClearABIContract.displayName = 'TronWebProto.ClearABIContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UpdateSettingContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UpdateSettingContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.UpdateSettingContract.displayName = 'TronWebProto.UpdateSettingContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UpdateEnergyLimitContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UpdateEnergyLimitContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.UpdateEnergyLimitContract.displayName = 'TronWebProto.UpdateEnergyLimitContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.SmartContractDataWrapper = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.SmartContractDataWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.SmartContractDataWrapper.displayName = 'TronWebProto.SmartContractDataWrapper';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.SmartContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.SmartContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.SmartContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.SmartContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                originAddress: msg.getOriginAddress_asB64(),
                contractAddress: msg.getContractAddress_asB64(),
                abi: (f = msg.getAbi()) && TronWebProto.SmartContract.ABI.toObject(includeInstance, f),
                bytecode: msg.getBytecode_asB64(),
                callValue: jspb.Message.getFieldWithDefault(msg, 5, 0),
                consumeUserResourcePercent: jspb.Message.getFieldWithDefault(msg, 6, 0),
                name: jspb.Message.getFieldWithDefault(msg, 7, ''),
                originEnergyLimit: jspb.Message.getFieldWithDefault(msg, 8, 0),
                codeHash: msg.getCodeHash_asB64(),
                trxHash: msg.getTrxHash_asB64(),
                version: jspb.Message.getFieldWithDefault(msg, 11, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.SmartContract}
 */
TronWebProto.SmartContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.SmartContract();
    return TronWebProto.SmartContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.SmartContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.SmartContract}
 */
TronWebProto.SmartContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOriginAddress(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setContractAddress(value);
                break;
            case 3:
                var value = new TronWebProto.SmartContract.ABI();
                reader.readMessage(value, TronWebProto.SmartContract.ABI.deserializeBinaryFromReader);
                msg.setAbi(value);
                break;
            case 4:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setBytecode(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCallValue(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setConsumeUserResourcePercent(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOriginEnergyLimit(value);
                break;
            case 9:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setCodeHash(value);
                break;
            case 10:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setTrxHash(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setVersion(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.SmartContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.SmartContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.SmartContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOriginAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getContractAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getAbi();
    if (f != null) {
        writer.writeMessage(3, f, TronWebProto.SmartContract.ABI.serializeBinaryToWriter);
    }
    f = message.getBytecode_asU8();
    if (f.length > 0) {
        writer.writeBytes(4, f);
    }
    f = message.getCallValue();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getConsumeUserResourcePercent();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getOriginEnergyLimit();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getCodeHash_asU8();
    if (f.length > 0) {
        writer.writeBytes(9, f);
    }
    f = message.getTrxHash_asU8();
    if (f.length > 0) {
        writer.writeBytes(10, f);
    }
    f = message.getVersion();
    if (f !== 0) {
        writer.writeInt32(11, f);
    }
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.SmartContract.ABI.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.SmartContract.ABI.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.SmartContract.ABI.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.SmartContract.ABI} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.SmartContract.ABI.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                entrysList: jspb.Message.toObjectList(
                    msg.getEntrysList(),
                    TronWebProto.SmartContract.ABI.Entry.toObject,
                    includeInstance
                ),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.SmartContract.ABI}
 */
TronWebProto.SmartContract.ABI.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.SmartContract.ABI();
    return TronWebProto.SmartContract.ABI.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.SmartContract.ABI} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.SmartContract.ABI}
 */
TronWebProto.SmartContract.ABI.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new TronWebProto.SmartContract.ABI.Entry();
                reader.readMessage(value, TronWebProto.SmartContract.ABI.Entry.deserializeBinaryFromReader);
                msg.addEntrys(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.ABI.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.SmartContract.ABI.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.SmartContract.ABI} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.SmartContract.ABI.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEntrysList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, TronWebProto.SmartContract.ABI.Entry.serializeBinaryToWriter);
    }
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.SmartContract.ABI.Entry.repeatedFields_ = [4, 5];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.SmartContract.ABI.Entry.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.SmartContract.ABI.Entry.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.SmartContract.ABI.Entry} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.SmartContract.ABI.Entry.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                anonymous: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
                constant: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
                name: jspb.Message.getFieldWithDefault(msg, 3, ''),
                inputsList: jspb.Message.toObjectList(
                    msg.getInputsList(),
                    TronWebProto.SmartContract.ABI.Entry.Param.toObject,
                    includeInstance
                ),
                outputsList: jspb.Message.toObjectList(
                    msg.getOutputsList(),
                    TronWebProto.SmartContract.ABI.Entry.Param.toObject,
                    includeInstance
                ),
                type: jspb.Message.getFieldWithDefault(msg, 6, 0),
                payable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
                statemutability: jspb.Message.getFieldWithDefault(msg, 8, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.SmartContract.ABI.Entry}
 */
TronWebProto.SmartContract.ABI.Entry.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.SmartContract.ABI.Entry();
    return TronWebProto.SmartContract.ABI.Entry.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.SmartContract.ABI.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.SmartContract.ABI.Entry}
 */
TronWebProto.SmartContract.ABI.Entry.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAnonymous(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setConstant(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = new TronWebProto.SmartContract.ABI.Entry.Param();
                reader.readMessage(value, TronWebProto.SmartContract.ABI.Entry.Param.deserializeBinaryFromReader);
                msg.addInputs(value);
                break;
            case 5:
                var value = new TronWebProto.SmartContract.ABI.Entry.Param();
                reader.readMessage(value, TronWebProto.SmartContract.ABI.Entry.Param.deserializeBinaryFromReader);
                msg.addOutputs(value);
                break;
            case 6:
                var value = /** @type {!TronWebProto.SmartContract.ABI.Entry.EntryType} */ (reader.readEnum());
                msg.setType(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPayable(value);
                break;
            case 8:
                var value = /** @type {!TronWebProto.SmartContract.ABI.Entry.StateMutabilityType} */ (reader.readEnum());
                msg.setStatemutability(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.SmartContract.ABI.Entry.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.SmartContract.ABI.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.SmartContract.ABI.Entry.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAnonymous();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getConstant();
    if (f) {
        writer.writeBool(2, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getInputsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, TronWebProto.SmartContract.ABI.Entry.Param.serializeBinaryToWriter);
    }
    f = message.getOutputsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, TronWebProto.SmartContract.ABI.Entry.Param.serializeBinaryToWriter);
    }
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getPayable();
    if (f) {
        writer.writeBool(7, f);
    }
    f = message.getStatemutability();
    if (f !== 0.0) {
        writer.writeEnum(8, f);
    }
};

/**
 * @enum {number}
 */
TronWebProto.SmartContract.ABI.Entry.EntryType = {
    UNKNOWNENTRYTYPE: 0,
    CONSTRUCTOR: 1,
    FUNCTION: 2,
    EVENT: 3,
    FALLBACK: 4,
    RECEIVE: 5,
    ERROR: 6,
};

/**
 * @enum {number}
 */
TronWebProto.SmartContract.ABI.Entry.StateMutabilityType = {
    UNKNOWNMUTABILITYTYPE: 0,
    PURE: 1,
    VIEW: 2,
    NONPAYABLE: 3,
    PAYABLE: 4,
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.SmartContract.ABI.Entry.Param.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.SmartContract.ABI.Entry.Param.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.SmartContract.ABI.Entry.Param} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.SmartContract.ABI.Entry.Param.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                indexed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
                name: jspb.Message.getFieldWithDefault(msg, 2, ''),
                type: jspb.Message.getFieldWithDefault(msg, 3, ''),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param}
 */
TronWebProto.SmartContract.ABI.Entry.Param.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.SmartContract.ABI.Entry.Param();
    return TronWebProto.SmartContract.ABI.Entry.Param.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.SmartContract.ABI.Entry.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param}
 */
TronWebProto.SmartContract.ABI.Entry.Param.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIndexed(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.SmartContract.ABI.Entry.Param.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.SmartContract.ABI.Entry.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.SmartContract.ABI.Entry.Param.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIndexed();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getType();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};

/**
 * optional bool indexed = 1;
 * @return {boolean}
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.getIndexed = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};

/**
 * @param {boolean} value
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param} returns this
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.setIndexed = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param} returns this
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string type = 3;
 * @return {string}
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param} returns this
 */
TronWebProto.SmartContract.ABI.Entry.Param.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional bool anonymous = 1;
 * @return {boolean}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getAnonymous = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};

/**
 * @param {boolean} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setAnonymous = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool constant = 2;
 * @return {boolean}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getConstant = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};

/**
 * @param {boolean} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setConstant = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * repeated Param inputs = 4;
 * @return {!Array<!TronWebProto.SmartContract.ABI.Entry.Param>}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getInputsList = function () {
    return /** @type{!Array<!TronWebProto.SmartContract.ABI.Entry.Param>} */ (
        jspb.Message.getRepeatedWrapperField(this, TronWebProto.SmartContract.ABI.Entry.Param, 4)
    );
};

/**
 * @param {!Array<!TronWebProto.SmartContract.ABI.Entry.Param>} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setInputsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!TronWebProto.SmartContract.ABI.Entry.Param=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.addInputs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, TronWebProto.SmartContract.ABI.Entry.Param, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.clearInputsList = function () {
    return this.setInputsList([]);
};

/**
 * repeated Param outputs = 5;
 * @return {!Array<!TronWebProto.SmartContract.ABI.Entry.Param>}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getOutputsList = function () {
    return /** @type{!Array<!TronWebProto.SmartContract.ABI.Entry.Param>} */ (
        jspb.Message.getRepeatedWrapperField(this, TronWebProto.SmartContract.ABI.Entry.Param, 5)
    );
};

/**
 * @param {!Array<!TronWebProto.SmartContract.ABI.Entry.Param>} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setOutputsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!TronWebProto.SmartContract.ABI.Entry.Param=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.SmartContract.ABI.Entry.Param}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.addOutputs = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, TronWebProto.SmartContract.ABI.Entry.Param, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.clearOutputsList = function () {
    return this.setOutputsList([]);
};

/**
 * optional EntryType type = 6;
 * @return {!TronWebProto.SmartContract.ABI.Entry.EntryType}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getType = function () {
    return /** @type {!TronWebProto.SmartContract.ABI.Entry.EntryType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {!TronWebProto.SmartContract.ABI.Entry.EntryType} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setType = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};

/**
 * optional bool payable = 7;
 * @return {boolean}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getPayable = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};

/**
 * @param {boolean} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setPayable = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional StateMutabilityType stateMutability = 8;
 * @return {!TronWebProto.SmartContract.ABI.Entry.StateMutabilityType}
 */
TronWebProto.SmartContract.ABI.Entry.prototype.getStatemutability = function () {
    return /** @type {!TronWebProto.SmartContract.ABI.Entry.StateMutabilityType} */ (
        jspb.Message.getFieldWithDefault(this, 8, 0)
    );
};

/**
 * @param {!TronWebProto.SmartContract.ABI.Entry.StateMutabilityType} value
 * @return {!TronWebProto.SmartContract.ABI.Entry} returns this
 */
TronWebProto.SmartContract.ABI.Entry.prototype.setStatemutability = function (value) {
    return jspb.Message.setProto3EnumField(this, 8, value);
};

/**
 * repeated Entry entrys = 1;
 * @return {!Array<!TronWebProto.SmartContract.ABI.Entry>}
 */
TronWebProto.SmartContract.ABI.prototype.getEntrysList = function () {
    return /** @type{!Array<!TronWebProto.SmartContract.ABI.Entry>} */ (
        jspb.Message.getRepeatedWrapperField(this, TronWebProto.SmartContract.ABI.Entry, 1)
    );
};

/**
 * @param {!Array<!TronWebProto.SmartContract.ABI.Entry>} value
 * @return {!TronWebProto.SmartContract.ABI} returns this
 */
TronWebProto.SmartContract.ABI.prototype.setEntrysList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!TronWebProto.SmartContract.ABI.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.SmartContract.ABI.Entry}
 */
TronWebProto.SmartContract.ABI.prototype.addEntrys = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, TronWebProto.SmartContract.ABI.Entry, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.SmartContract.ABI} returns this
 */
TronWebProto.SmartContract.ABI.prototype.clearEntrysList = function () {
    return this.setEntrysList([]);
};

/**
 * optional bytes origin_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.SmartContract.prototype.getOriginAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes origin_address = 1;
 * This is a type-conversion wrapper around `getOriginAddress()`
 * @return {string}
 */
TronWebProto.SmartContract.prototype.getOriginAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOriginAddress()));
};

/**
 * optional bytes origin_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOriginAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.prototype.getOriginAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOriginAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setOriginAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes contract_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.SmartContract.prototype.getContractAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes contract_address = 2;
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {string}
 */
TronWebProto.SmartContract.prototype.getContractAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getContractAddress()));
};

/**
 * optional bytes contract_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.prototype.getContractAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getContractAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setContractAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional ABI abi = 3;
 * @return {?TronWebProto.SmartContract.ABI}
 */
TronWebProto.SmartContract.prototype.getAbi = function () {
    return /** @type{?TronWebProto.SmartContract.ABI} */ (jspb.Message.getWrapperField(this, TronWebProto.SmartContract.ABI, 3));
};

/**
 * @param {?TronWebProto.SmartContract.ABI|undefined} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setAbi = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.clearAbi = function () {
    return this.setAbi(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.SmartContract.prototype.hasAbi = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bytes bytecode = 4;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.SmartContract.prototype.getBytecode = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * optional bytes bytecode = 4;
 * This is a type-conversion wrapper around `getBytecode()`
 * @return {string}
 */
TronWebProto.SmartContract.prototype.getBytecode_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getBytecode()));
};

/**
 * optional bytes bytecode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytecode()`
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.prototype.getBytecode_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getBytecode()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setBytecode = function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional int64 call_value = 5;
 * @return {number}
 */
TronWebProto.SmartContract.prototype.getCallValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setCallValue = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional int64 consume_user_resource_percent = 6;
 * @return {number}
 */
TronWebProto.SmartContract.prototype.getConsumeUserResourcePercent = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setConsumeUserResourcePercent = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional string name = 7;
 * @return {string}
 */
TronWebProto.SmartContract.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional int64 origin_energy_limit = 8;
 * @return {number}
 */
TronWebProto.SmartContract.prototype.getOriginEnergyLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setOriginEnergyLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional bytes code_hash = 9;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.SmartContract.prototype.getCodeHash = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * optional bytes code_hash = 9;
 * This is a type-conversion wrapper around `getCodeHash()`
 * @return {string}
 */
TronWebProto.SmartContract.prototype.getCodeHash_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getCodeHash()));
};

/**
 * optional bytes code_hash = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodeHash()`
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.prototype.getCodeHash_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getCodeHash()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setCodeHash = function (value) {
    return jspb.Message.setProto3BytesField(this, 9, value);
};

/**
 * optional bytes trx_hash = 10;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.SmartContract.prototype.getTrxHash = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * optional bytes trx_hash = 10;
 * This is a type-conversion wrapper around `getTrxHash()`
 * @return {string}
 */
TronWebProto.SmartContract.prototype.getTrxHash_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getTrxHash()));
};

/**
 * optional bytes trx_hash = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTrxHash()`
 * @return {!Uint8Array}
 */
TronWebProto.SmartContract.prototype.getTrxHash_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getTrxHash()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setTrxHash = function (value) {
    return jspb.Message.setProto3BytesField(this, 10, value);
};

/**
 * optional int32 version = 11;
 * @return {number}
 */
TronWebProto.SmartContract.prototype.getVersion = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.SmartContract} returns this
 */
TronWebProto.SmartContract.prototype.setVersion = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.ContractState.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.ContractState.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.ContractState} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.ContractState.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                energyUsage: jspb.Message.getFieldWithDefault(msg, 1, 0),
                energyFactor: jspb.Message.getFieldWithDefault(msg, 2, 0),
                updateCycle: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.ContractState}
 */
TronWebProto.ContractState.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.ContractState();
    return TronWebProto.ContractState.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.ContractState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.ContractState}
 */
TronWebProto.ContractState.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEnergyUsage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEnergyFactor(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdateCycle(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.ContractState.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.ContractState.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.ContractState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.ContractState.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEnergyUsage();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getEnergyFactor();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getUpdateCycle();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};

/**
 * optional int64 energy_usage = 1;
 * @return {number}
 */
TronWebProto.ContractState.prototype.getEnergyUsage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.ContractState} returns this
 */
TronWebProto.ContractState.prototype.setEnergyUsage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int64 energy_factor = 2;
 * @return {number}
 */
TronWebProto.ContractState.prototype.getEnergyFactor = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.ContractState} returns this
 */
TronWebProto.ContractState.prototype.setEnergyFactor = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 update_cycle = 3;
 * @return {number}
 */
TronWebProto.ContractState.prototype.getUpdateCycle = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.ContractState} returns this
 */
TronWebProto.ContractState.prototype.setUpdateCycle = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.CreateSmartContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.CreateSmartContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.CreateSmartContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.CreateSmartContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                newContract: (f = msg.getNewContract()) && TronWebProto.SmartContract.toObject(includeInstance, f),
                callTokenValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
                tokenId: jspb.Message.getFieldWithDefault(msg, 4, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.CreateSmartContract}
 */
TronWebProto.CreateSmartContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.CreateSmartContract();
    return TronWebProto.CreateSmartContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.CreateSmartContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.CreateSmartContract}
 */
TronWebProto.CreateSmartContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = new TronWebProto.SmartContract();
                reader.readMessage(value, TronWebProto.SmartContract.deserializeBinaryFromReader);
                msg.setNewContract(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCallTokenValue(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTokenId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.CreateSmartContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.CreateSmartContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.CreateSmartContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.CreateSmartContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getNewContract();
    if (f != null) {
        writer.writeMessage(2, f, TronWebProto.SmartContract.serializeBinaryToWriter);
    }
    f = message.getCallTokenValue();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTokenId();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.CreateSmartContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.CreateSmartContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.CreateSmartContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.CreateSmartContract} returns this
 */
TronWebProto.CreateSmartContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional SmartContract new_contract = 2;
 * @return {?TronWebProto.SmartContract}
 */
TronWebProto.CreateSmartContract.prototype.getNewContract = function () {
    return /** @type{?TronWebProto.SmartContract} */ (jspb.Message.getWrapperField(this, TronWebProto.SmartContract, 2));
};

/**
 * @param {?TronWebProto.SmartContract|undefined} value
 * @return {!TronWebProto.CreateSmartContract} returns this
 */
TronWebProto.CreateSmartContract.prototype.setNewContract = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.CreateSmartContract} returns this
 */
TronWebProto.CreateSmartContract.prototype.clearNewContract = function () {
    return this.setNewContract(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.CreateSmartContract.prototype.hasNewContract = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int64 call_token_value = 3;
 * @return {number}
 */
TronWebProto.CreateSmartContract.prototype.getCallTokenValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.CreateSmartContract} returns this
 */
TronWebProto.CreateSmartContract.prototype.setCallTokenValue = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int64 token_id = 4;
 * @return {number}
 */
TronWebProto.CreateSmartContract.prototype.getTokenId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.CreateSmartContract} returns this
 */
TronWebProto.CreateSmartContract.prototype.setTokenId = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.TriggerSmartContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.TriggerSmartContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.TriggerSmartContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.TriggerSmartContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                contractAddress: msg.getContractAddress_asB64(),
                callValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
                data: msg.getData_asB64(),
                callTokenValue: jspb.Message.getFieldWithDefault(msg, 5, 0),
                tokenId: jspb.Message.getFieldWithDefault(msg, 6, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.TriggerSmartContract}
 */
TronWebProto.TriggerSmartContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.TriggerSmartContract();
    return TronWebProto.TriggerSmartContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.TriggerSmartContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.TriggerSmartContract}
 */
TronWebProto.TriggerSmartContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setContractAddress(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCallValue(value);
                break;
            case 4:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setData(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCallTokenValue(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTokenId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.TriggerSmartContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.TriggerSmartContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.TriggerSmartContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.TriggerSmartContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getContractAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getCallValue();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getData_asU8();
    if (f.length > 0) {
        writer.writeBytes(4, f);
    }
    f = message.getCallTokenValue();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getTokenId();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TriggerSmartContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.TriggerSmartContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TriggerSmartContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TriggerSmartContract} returns this
 */
TronWebProto.TriggerSmartContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes contract_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TriggerSmartContract.prototype.getContractAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes contract_address = 2;
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {string}
 */
TronWebProto.TriggerSmartContract.prototype.getContractAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getContractAddress()));
};

/**
 * optional bytes contract_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TriggerSmartContract.prototype.getContractAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getContractAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TriggerSmartContract} returns this
 */
TronWebProto.TriggerSmartContract.prototype.setContractAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional int64 call_value = 3;
 * @return {number}
 */
TronWebProto.TriggerSmartContract.prototype.getCallValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.TriggerSmartContract} returns this
 */
TronWebProto.TriggerSmartContract.prototype.setCallValue = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bytes data = 4;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TriggerSmartContract.prototype.getData = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * optional bytes data = 4;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
TronWebProto.TriggerSmartContract.prototype.getData_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getData()));
};

/**
 * optional bytes data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
TronWebProto.TriggerSmartContract.prototype.getData_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getData()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TriggerSmartContract} returns this
 */
TronWebProto.TriggerSmartContract.prototype.setData = function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional int64 call_token_value = 5;
 * @return {number}
 */
TronWebProto.TriggerSmartContract.prototype.getCallTokenValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.TriggerSmartContract} returns this
 */
TronWebProto.TriggerSmartContract.prototype.setCallTokenValue = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional int64 token_id = 6;
 * @return {number}
 */
TronWebProto.TriggerSmartContract.prototype.getTokenId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.TriggerSmartContract} returns this
 */
TronWebProto.TriggerSmartContract.prototype.setTokenId = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.ClearABIContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.ClearABIContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.ClearABIContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.ClearABIContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                contractAddress: msg.getContractAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.ClearABIContract}
 */
TronWebProto.ClearABIContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.ClearABIContract();
    return TronWebProto.ClearABIContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.ClearABIContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.ClearABIContract}
 */
TronWebProto.ClearABIContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setContractAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.ClearABIContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.ClearABIContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.ClearABIContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.ClearABIContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getContractAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.ClearABIContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.ClearABIContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.ClearABIContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.ClearABIContract} returns this
 */
TronWebProto.ClearABIContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes contract_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.ClearABIContract.prototype.getContractAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes contract_address = 2;
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {string}
 */
TronWebProto.ClearABIContract.prototype.getContractAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getContractAddress()));
};

/**
 * optional bytes contract_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.ClearABIContract.prototype.getContractAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getContractAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.ClearABIContract} returns this
 */
TronWebProto.ClearABIContract.prototype.setContractAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.UpdateSettingContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.UpdateSettingContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.UpdateSettingContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.UpdateSettingContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                contractAddress: msg.getContractAddress_asB64(),
                consumeUserResourcePercent: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UpdateSettingContract}
 */
TronWebProto.UpdateSettingContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.UpdateSettingContract();
    return TronWebProto.UpdateSettingContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UpdateSettingContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UpdateSettingContract}
 */
TronWebProto.UpdateSettingContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setContractAddress(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setConsumeUserResourcePercent(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UpdateSettingContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.UpdateSettingContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UpdateSettingContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UpdateSettingContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getContractAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getConsumeUserResourcePercent();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateSettingContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UpdateSettingContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateSettingContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateSettingContract} returns this
 */
TronWebProto.UpdateSettingContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes contract_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateSettingContract.prototype.getContractAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes contract_address = 2;
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {string}
 */
TronWebProto.UpdateSettingContract.prototype.getContractAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getContractAddress()));
};

/**
 * optional bytes contract_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateSettingContract.prototype.getContractAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getContractAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateSettingContract} returns this
 */
TronWebProto.UpdateSettingContract.prototype.setContractAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional int64 consume_user_resource_percent = 3;
 * @return {number}
 */
TronWebProto.UpdateSettingContract.prototype.getConsumeUserResourcePercent = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.UpdateSettingContract} returns this
 */
TronWebProto.UpdateSettingContract.prototype.setConsumeUserResourcePercent = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.UpdateEnergyLimitContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.UpdateEnergyLimitContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.UpdateEnergyLimitContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.UpdateEnergyLimitContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                contractAddress: msg.getContractAddress_asB64(),
                originEnergyLimit: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UpdateEnergyLimitContract}
 */
TronWebProto.UpdateEnergyLimitContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.UpdateEnergyLimitContract();
    return TronWebProto.UpdateEnergyLimitContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UpdateEnergyLimitContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UpdateEnergyLimitContract}
 */
TronWebProto.UpdateEnergyLimitContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setContractAddress(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOriginEnergyLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.UpdateEnergyLimitContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UpdateEnergyLimitContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UpdateEnergyLimitContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getContractAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getOriginEnergyLimit();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateEnergyLimitContract} returns this
 */
TronWebProto.UpdateEnergyLimitContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes contract_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getContractAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes contract_address = 2;
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {string}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getContractAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getContractAddress()));
};

/**
 * optional bytes contract_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getContractAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getContractAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UpdateEnergyLimitContract} returns this
 */
TronWebProto.UpdateEnergyLimitContract.prototype.setContractAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional int64 origin_energy_limit = 3;
 * @return {number}
 */
TronWebProto.UpdateEnergyLimitContract.prototype.getOriginEnergyLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.UpdateEnergyLimitContract} returns this
 */
TronWebProto.UpdateEnergyLimitContract.prototype.setOriginEnergyLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.SmartContractDataWrapper.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.SmartContractDataWrapper.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.SmartContractDataWrapper} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.SmartContractDataWrapper.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                smartContract: (f = msg.getSmartContract()) && TronWebProto.SmartContract.toObject(includeInstance, f),
                runtimecode: msg.getRuntimecode_asB64(),
                contractState: (f = msg.getContractState()) && TronWebProto.ContractState.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.SmartContractDataWrapper}
 */
TronWebProto.SmartContractDataWrapper.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.SmartContractDataWrapper();
    return TronWebProto.SmartContractDataWrapper.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.SmartContractDataWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.SmartContractDataWrapper}
 */
TronWebProto.SmartContractDataWrapper.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new TronWebProto.SmartContract();
                reader.readMessage(value, TronWebProto.SmartContract.deserializeBinaryFromReader);
                msg.setSmartContract(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setRuntimecode(value);
                break;
            case 3:
                var value = new TronWebProto.ContractState();
                reader.readMessage(value, TronWebProto.ContractState.deserializeBinaryFromReader);
                msg.setContractState(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.SmartContractDataWrapper.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.SmartContractDataWrapper.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.SmartContractDataWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.SmartContractDataWrapper.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSmartContract();
    if (f != null) {
        writer.writeMessage(1, f, TronWebProto.SmartContract.serializeBinaryToWriter);
    }
    f = message.getRuntimecode_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getContractState();
    if (f != null) {
        writer.writeMessage(3, f, TronWebProto.ContractState.serializeBinaryToWriter);
    }
};

/**
 * optional SmartContract smart_contract = 1;
 * @return {?TronWebProto.SmartContract}
 */
TronWebProto.SmartContractDataWrapper.prototype.getSmartContract = function () {
    return /** @type{?TronWebProto.SmartContract} */ (jspb.Message.getWrapperField(this, TronWebProto.SmartContract, 1));
};

/**
 * @param {?TronWebProto.SmartContract|undefined} value
 * @return {!TronWebProto.SmartContractDataWrapper} returns this
 */
TronWebProto.SmartContractDataWrapper.prototype.setSmartContract = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.SmartContractDataWrapper} returns this
 */
TronWebProto.SmartContractDataWrapper.prototype.clearSmartContract = function () {
    return this.setSmartContract(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.SmartContractDataWrapper.prototype.hasSmartContract = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bytes runtimecode = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.SmartContractDataWrapper.prototype.getRuntimecode = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes runtimecode = 2;
 * This is a type-conversion wrapper around `getRuntimecode()`
 * @return {string}
 */
TronWebProto.SmartContractDataWrapper.prototype.getRuntimecode_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getRuntimecode()));
};

/**
 * optional bytes runtimecode = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRuntimecode()`
 * @return {!Uint8Array}
 */
TronWebProto.SmartContractDataWrapper.prototype.getRuntimecode_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getRuntimecode()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.SmartContractDataWrapper} returns this
 */
TronWebProto.SmartContractDataWrapper.prototype.setRuntimecode = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional ContractState contract_state = 3;
 * @return {?TronWebProto.ContractState}
 */
TronWebProto.SmartContractDataWrapper.prototype.getContractState = function () {
    return /** @type{?TronWebProto.ContractState} */ (jspb.Message.getWrapperField(this, TronWebProto.ContractState, 3));
};

/**
 * @param {?TronWebProto.ContractState|undefined} value
 * @return {!TronWebProto.SmartContractDataWrapper} returns this
 */
TronWebProto.SmartContractDataWrapper.prototype.setContractState = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.SmartContractDataWrapper} returns this
 */
TronWebProto.SmartContractDataWrapper.prototype.clearContractState = function () {
    return this.setContractState(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.SmartContractDataWrapper.prototype.hasContractState = function () {
    return jspb.Message.getField(this, 3) != null;
};

goog.object.extend(exports, TronWebProto);
